<template>
    <div id="div-order-list" class="order-list">
        <b-sidebar
            id="sidebar-right"
            v-model="sidebarVisible"
            class="sidebar-manage-table"
            shadow
            right
            bg-variant="white"
            width="407px"
            backdrop
        >
            <template #header>
                <div class="p-2 d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{ $t("ordersList.ManageTable") }}
                    </span>

                    <span
                        v-b-toggle.sidebar-right
                        class="close-icon"
                        @click="getConfigTable()"
                    >
                        <close-icon />
                    </span>
                </div>

                <div class="separator" />

                <span class="d-flex info-sidebar">{{
                    $t("ordersList.infoColumn")
                }}</span>
            </template>

            <template>
                <b-col cols="12" class="p-0 itens-drag">
                    <draggable
                        v-if="reRenderSelects"
                        tag="ul"
                        :list="listConfig"
                        class="list-group"
                        handle=".handle"
                        v-bind="dragOptions"
                    >
                        <transition-group>
                            <li
                                v-for="(item, index) in listConfig"
                                :key="index"
                                class="list-group-item"
                                @click="openSelect(index)"
                            >
                                <DragIcon class="handle" />

                                <div class="col-input-icon">
                                    <SingleSelect
                                        v-if="item.selectVisible"
                                        id="input-workcenter2"
                                        :key="`${item.name}-${index}`"
                                        :placeholder="$t('Select')"
                                        :option-selected="
                                            collunsOptions.find(
                                                (f) => f.value == item.name
                                            )
                                        "
                                        :options="collunsOptionsHelper"
                                        :translate="true"
                                        :disabled="!item.removed"
                                        :state="
                                            !item.name && submited
                                                ? false
                                                : null
                                        "
                                        @input="
                                            (value) => {
                                                item.name = value
                                                    ? value.value
                                                    : value;
                                                updateOptionsList(listConfig);
                                            }
                                        "
                                    >
                                        {{ $t("RequiredField") }}
                                    </SingleSelect>

                                    <div
                                        v-else
                                        class="name-column w-100"
                                        :class="{ disabled: !item.removed }"
                                    >
                                        {{
                                            $t(
                                                collunsOptions.find(
                                                    (f) => f.value == item.name
                                                ).text
                                            )
                                        }}
                                    </div>

                                    <div
                                        v-if="!item.removed"
                                        :id="`tooltip-info-collumn-${index}`"
                                        class="icon-action"
                                    >
                                        <InfoIcon />
                                    </div>

                                    <div
                                        v-else
                                        :id="`tooltip-delete-collumn-${index}`"
                                        class="icon-action trash"
                                        @click="removeItem(index)"
                                    >
                                        <DeleteIcon />
                                    </div>

                                    <b-tooltip
                                        :target="`tooltip-info-collumn-${index}`"
                                        triggers="hover blur"
                                        positioning="top"
                                        placement="top"
                                        boundary-padding="0"
                                        noninteractive
                                    >
                                        <div class="info-tooltip">
                                            {{ $t("ordersList.CannotRemove") }}
                                        </div>
                                    </b-tooltip>

                                    <b-tooltip
                                        :target="`tooltip-delete-collumn-${index}`"
                                        triggers="hover blur"
                                        positioning="top"
                                        placement="top"
                                        boundary-padding="0"
                                        noninteractive
                                    >
                                        <div class="info-tooltip">
                                            {{ $t("ordersList.RemoveColumn") }}
                                        </div>
                                    </b-tooltip>
                                </div>
                            </li>
                        </transition-group>
                    </draggable>

                    <b-button
                        class="btn-add-collumn btn-footer"
                        :disabled="listConfig.length == columnDefsBackup.length"
                        @click="addColumn()"
                    >
                        <AddCircleIcon class="add-circle-icon" />
                        {{ $t("ordersList.AddColumn") }}
                    </b-button>
                </b-col>
            </template>

            <template #footer>
                <b-col md="12" class="footer-sidebar">
                    <b-button
                        class="btn-reset btn-footer"
                        @click="getConfigTable()"
                    >
                        <span class="btn-reset-text">
                            {{ $t("ordersList.RestoreConfig") }}
                        </span>
                        <RestoreIcon class="restore-icon" />
                    </b-button>
                    <div class="btn-footer-left">
                        <b-button
                            v-b-toggle.sidebar-right
                            class="btn-cancel btn-footer"
                            @click="getConfigTable()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer"
                            @click="saveTableConfig()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>

        <b-col cols="12" class="p-0">
            <b-card>
                <div class="card-title-order-list">
                    <div class="card-orders-list-title">
                        <span>{{ $t("ordersList.title") }}</span>
                    </div>
                    <periodFilter
                        ref="periodFilter"
                        position="left"
                        :options="periodFilterOptions"
                        :max-range-dates="30"
                        @change="changeFilter"
                    />
                </div>

                <b-col cols="12" class="p-0 mt-0 mt-sm-1">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="3"
                            md="3"
                            lg="2"
                            xl="2"
                            class="pr-1 pr-sm-0"
                        >
                            <b-button
                                v-b-toggle.sidebar-right
                                class="btn-title-action manage-table"
                                :disabled="btnDisabled"
                            >
                                {{ $t("ordersList.ManageTable") }}
                            </b-button>
                        </b-col>

                        <b-col
                            cols="12"
                            sm="9"
                            md="9"
                            lg="10"
                            xl="10"
                            class="div-custom-input"
                        >
                            <b-form-tags
                                ref="formTagOrders"
                                v-model="filter.textFilter"
                                class="input-search-orders"
                                duplicate-tag-text=""
                                :placeholder="
                                    filter.textFilter.length === 0
                                        ? $t('SearchPlaceholderDefault')
                                        : ''
                                "
                                remove-on-delete
                                @input="getOrders()"
                            />
                        </b-col>
                    </b-row>
                </b-col>

                <div class="mt-1 table-container">
                    <TableAgGrid
                        v-if="showGrid"
                        ref="tableAgGrid"
                        :column-defs="columnDefs"
                        :row-data="rowData"
                        :filter="filter"
                        @updatePageNumber="(value) => updatePageNumber(value)"
                        @updatepageSize="(value) => updatepageSize(value)"
                        @changeColumn="(params) => onChangeColumn(params)"
                    />
                </div>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import {
    BFormGroup,
    BFormInput,
    BDropdown,
    BButton,
    BDropdownItem,
    BCard,
    BSidebar,
    VBToggle,
    BRow,
    BTooltip,
    BFormTags,
    BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import moment from "moment";
import { mapMutations } from "vuex";
import periodFilter from "components-code/period-filter-component";

export default {
    components: {
        BFormGroup,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BCard,
        BSidebar,
        BButton,
        BRow,
        draggable,
        BTooltip,
        BFormTags,
        BCol,
        periodFilter,
        OrderView: () => import("./OrderView.vue"),
        DropdownIcon: () => import("@/assets/images/icons/dropdown-icon.svg"),
        DragIcon: () => import("@/assets/images/pages/drag-icon.svg"),
        SingleSelect: () =>
            import("@core/components/multiselect/SingleSelect.vue"),
        DeleteIcon: () => import("@/assets/images/pages/delete-trash-icon.svg"),
        InfoIcon: () => import("@/assets/images/pages/info-outline-icon.svg"),
        CloseIcon: () => import("@/assets/images/icons/close-icon.svg"),
        FormPicker: () => import("@core/components/form-picker/FormPicker.vue"),
        TableAgGrid: () =>
            import("@core/components/table-ag-grid/TableAgGrid.vue"),
        AddCircleIcon: () =>
            import("@/assets/images/pages/plus-circle-icon.svg"),
        ProductionIcon: () =>
            import("@/assets/images/icons/production-progress-icon.svg"),
        RestoreIcon: () => import("@/assets/images/icons/restore-icon.svg"),
    },
    directives: {
        "b-toggle": VBToggle,
        Ripple,
    },
    data() {
        return {
            prefix:
                localStorage.getItem("prefix") ||
                this.$router.currentRoute.params.prefix,
            currentSite: localStorage.getItem("currentSite"),
            pageLength: 10,
            dir: false,
            flagDisableDrag: false,
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            },
            collunsOptions: [
                { value: "actions", text: "IntegrationPage.Action" },
                { value: "status", text: "Status" },
                { value: "order_no", text: "ordersList.OrderNo" },
                { value: "op_no", text: "ordersList.OpNo" },
                { value: "operation_name", text: "ordersList.OperationName" },
                { value: "part_no", text: "ordersList.PartNo" },
                { value: "product", text: "ordersList.Product" },
                { value: "resource", text: "ordersList.Resource" },
                { value: "setup_start", text: "ordersList.SetupStart" },
                { value: "start_time", text: "ordersList.StartTime" },
                { value: "end_time", text: "ordersList.EndTime" },
                {
                    value: "planned_quantity",
                    text: "ordersList.PlannedQuantity",
                },
                {
                    value: "produced_quantity",
                    text: "ordersList.QuantityProduced",
                },
                { value: "rework_quantity", text: "ordersList.QuantityRework" },
                {
                    value: "refuse_quantity",
                    text: "ordersList.QuantityRefused",
                },
                { value: "total_completed", text: "ordersList.TotalCompleted" },
                { value: "date_produced", text: "ordersList.DateProduced" },
                { value: "priority", text: "OperationProgress.Priority" },
                { value: "due_date", text: "ordersList.DueDate" },
                {
                    value: "earliest_start_date",
                    text: "ordersList.EarliestStartDate",
                },
                { value: "order_start", text: "ordersList.OrderStart" },
                { value: "order_end", text: "ordersList.OrderEnd" },
                { value: "observations", text: "ordersList.Observations" },
                { value: "items_per_hour", text: "ordersList.ItemsPerHour" },
                {
                    value: "process_time_type",
                    text: "ordersList.ProcessTimeType",
                },
                { value: "op_time_per_item", text: "ordersList.TimePerItem" },
                { value: "batch_time", text: "ordersList.BatchTime" },
                {
                    value: "resource_op_time",
                    text: "ordersList.ResourceOpTime",
                },
                {
                    value: "resource_batch_time",
                    text: "ordersList.ResourceBatchTime",
                },
                { value: "att_1", text: "att_1" },
                { value: "att_2", text: "att_2" },
                { value: "att_3", text: "att_3" },
                { value: "att_4", text: "att_4" },
                { value: "att_5", text: "att_5" },
                { value: "string_attribute_1", text: "string_attribute_1" },
                { value: "string_attribute_2", text: "string_attribute_2" },
                { value: "string_attribute_3", text: "string_attribute_3" },
                { value: "string_attribute_4", text: "string_attribute_4" },
                { value: "string_attribute_5", text: "string_attribute_5" },
                {
                    value: "numerical_attribute_1",
                    text: "numerical_attribute_1",
                },
                {
                    value: "numerical_attribute_2",
                    text: "numerical_attribute_2",
                },
                {
                    value: "numerical_attribute_3",
                    text: "numerical_attribute_3",
                },
                {
                    value: "numerical_attribute_4",
                    text: "numerical_attribute_4",
                },
                {
                    value: "numerical_attribute_5",
                    text: "numerical_attribute_5",
                },
                { value: "created_at", text: "ordersList.CreatedAt" },
                { value: "updated_at", text: "ordersList.UpdatedAt" },
            ],
            collunsOptionsHelper: [],
            listConfig: [],
            dragging: false,
            columnDefsBackup: [
                {
                    headerName: "IntegrationPage.Actions",
                    field: "actions",
                    resizable: false,
                    width: 100,
                    cellRenderer: "CellAction",
                    cellRendererParams: {
                        parentContext: this,
                    },
                    sortable: false,
                },
                {
                    headerName: "ProductionProgress.Status",
                    field: "status",
                    resizable: true,
                    cellRenderer: this.cellStatusCustom,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.OrderNo",
                    field: "order_no",
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.OpNo",
                    field: "op_no",
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.OperationName",
                    field: "operation_name",
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.PartNo",
                    field: "part_no",
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.Product",
                    field: "product",
                    resizable: true,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.Resource",
                    field: "resource",
                    resizable: false,
                    cellRenderer: this.cellEllipsisTooltip,
                    headerComponentParams: { parentContext: this },
                },
                {
                    headerName: "ordersList.SetupStart",
                    field: "setup_start",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.setup_start),
                },
                {
                    headerName: "ordersList.StartTime",
                    field: "start_time",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.start_time),
                },
                {
                    headerName: "ordersList.EndTime",
                    field: "end_time",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.end_time),
                },
                {
                    headerName: "ordersList.PlannedQuantity",
                    field: "planned_quantity",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.QuantityProduced",
                    field: "produced_quantity",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.QuantityRework",
                    field: "rework_quantity",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.QuantityRefused",
                    field: "refuse_quantity",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.TotalCompleted",
                    field: "total_completed",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.DateProduced",
                    field: "date_produced",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.date_produced),
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "OperationProgress.Priority",
                    field: "priority",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.DueDate",
                    field: "due_date",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.due_date),
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.EarliestStartDate",
                    field: "earliest_start_date",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.earliest_start_date),
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.OrderStart",
                    field: "order_start",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.order_start),
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.OrderEnd",
                    field: "order_end",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    valueGetter: (params) =>
                        this.getFormatedDate(params.data.order_end),
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.Observations",
                    field: "observations",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.ItemsPerHour",
                    field: "items_per_hour",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.ProcessTimeType",
                    field: "process_time_type",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.TimePerItem",
                    field: "op_time_per_item",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.BatchTime",
                    field: "batch_time",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.ResourceOpTime",
                    field: "resource_op_time",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.ResourceBatchTime",
                    field: "resource_batch_time",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "att_1",
                    field: "att_1",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "att_2",
                    field: "att_2",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "att_3",
                    field: "att_3",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "att_4",
                    field: "att_4",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "att_5",
                    field: "att_5",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "string_attribute_1",
                    field: "string_attribute_1",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "string_attribute_2",
                    field: "string_attribute_2",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "string_attribute_3",
                    field: "string_attribute_3",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "string_attribute_4",
                    field: "string_attribute_4",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "string_attribute_5",
                    field: "string_attribute_5",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "numerical_attribute_1",
                    field: "numerical_attribute_1",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "numerical_attribute_2",
                    field: "numerical_attribute_2",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "numerical_attribute_3",
                    field: "numerical_attribute_3",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "numerical_attribute_4",
                    field: "numerical_attribute_4",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "numerical_attribute_5",
                    field: "numerical_attribute_5",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.CreatedAt",
                    field: "created_at",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
                {
                    headerName: "ordersList.UpdatedAt",
                    field: "updated_at",
                    resizable: true,
                    headerComponentParams: { parentContext: this },
                    cellRenderer: this.cellEllipsisTooltip,
                },
            ],
            columnDefs: [],
            rowData: [],
            orderId: "",
            statusList: [],
            filter: {
                pageNumber: 1,
                pageSize: 10,
                totalItems: 0,
                sort: "asc",
                AllPeriod: 0,
                days: 7,
                startPeriod: "",
                endPeriod: "",
                fieldSort: "setup_start",
                textFilter: [],
            },
            submited: false,
            sidebarVisible: false,
            reRenderSelects: true,
            formPickerValue: [],
            showGrid: true,
            language: localStorage.getItem("language"),
            btnDisabled: true,
            periodFilterOptions: [
                {
                    value: 7,
                    text: "PeriodFilterComponent.7Days",
                },
                {
                    value: 45,
                    text: "PeriodFilterComponent.45Days",
                },
                {
                    value: 60,
                    text: "PeriodFilterComponent.60Days",
                },
                {
                    value: 0,
                    text: "PeriodFilterComponent.Custom",
                },
            ],
        };
    },
    watch: {
        "filter.pageNumber": function () {
            this.getOrders();
        },
        "filter.pageSize": function () {
            this.getOrders();
        },
        "filter.sort": function () {
            this.getOrders();
        },
        listConfig(values) {
            this.updateOptionsList(values);
        },
    },
    methods: {
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
        cleanFilters() {
            this.filter.AllPeriod = 0;
            this.filter.days = "";
            this.filter.startPeriod = "";
            this.filter.endPeriod = "";
        },
        changeFilter({ option, datepicker }) {
            this.cleanFilters();

            if (option !== null) {
                this.filter.AllPeriod = option === 3000 ? 1 : 0;
                this.filter.days = option !== 3000 ? option : "";
                this.getOrders();
                return;
            }

            this.filter.startPeriod = moment(new Date(datepicker[0])).format(
                "YYYY-MM-DD"
            );
            this.filter.endPeriod =
                datepicker?.length > 1
                    ? moment(new Date(datepicker[1])).format("YYYY-MM-DD")
                    : moment(new Date(datepicker[0])).format("YYYY-MM-DD");

            this.getOrders();
        },
        getOrders() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.btnDisabled = true;

            this.$http
                .get(
                    `/api/${this.prefix}/order?
                filterDays=${this.filter.days}
                &startDate=${this.filter.startPeriod}
                &endDate=${this.filter.endPeriod}
                &allPeriod=${this.filter.AllPeriod}
                &textFilter=${this.filter.textFilter}
                &site_guid=${this.currentSite}
                &pageSize=${this.filter.pageSize}
                &pageNumber=${this.filter.pageNumber}
                &sort=${this.filter.sort}
                &fieldSort=${this.filter.fieldSort}`
                )
                .then((res) => {
                    this.rowData = res.data.orders;
                    this.filter.totalItems = res.data.filter.totalItems;
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    this.btnDisabled = false;

                    const loadingMySfc = this.$cookies.get('modalNoSites');
                    const hasSites = this.$cookies.get('userInfo').userData.sites;

                    if (hasSites.length === 0 && (!loadingMySfc || !loadingMySfc.mysfc)) {
                        this.$swal({
                            title: this.$t("ordersList.WarningTitle"),
                            text: this.$t("ordersList.WarningText"),
                            showCancelButton: false,
                            showConfirmButton: false,
                            showCloseButton: true,
                            customClass: {
                                container: "swal-warning",
                                confirmButton: "btn btn-primary",
                                cancelButton: "btn btn-outline-danger ml-1",
                            },
                        });
                        const domain = window.location.href.includes('indsiders.com');
                        this.$cookies.set(
                            'modalNoSites',
                            { mysfc: true, myconnect: false },
                            null,
                            null,
                            domain ? 'indsiders.com' : null
                        );
                    }
                    
                });
        },
        getConfigTable() {
            this.$http
                .get(
                    `/api/${this.$router.currentRoute.params.prefix}/order/config-table`
                )
                .then((res) => {
                    if (res.data.config) {
                        this.listConfig = res.data.config;
                        this.columnDefs = [];

                        const columnDefsBackupIndex = {};
                        for (let i = 0; i < this.columnDefsBackup.length; i++) {
                            columnDefsBackupIndex[
                                this.columnDefsBackup[i].field
                            ] = i;
                        }

                        for (const item of res.data.config) {
                            const columnIndex =
                                columnDefsBackupIndex[item.name];

                            if (columnIndex !== undefined) {
                                const column =
                                    this.columnDefsBackup[columnIndex];
                                column.width =
                                    column.field === "actions"
                                        ? 100
                                        : item.width;
                                this.columnDefs.push(column);
                            }
                        }
                    }
                });
        },
        getBackgroundColor(status) {
            const color = this.statusList.find((el) => el.text == status);
            if (color) return `background-color:${color.color};`;
            return "background-color:secondary";
        },
        setOrderId(id) {
            this.orderId = id;
        },
        updatepageSize(value) {
            this.filter.pageSize = value;
        },
        updatePageNumber(value) {
            this.filter.pageNumber = value;
        },
        getFormatedDate(date) {
            if (date) {
                if (this.language == "pt")
                    return moment(date).format("DD-MM-YY HH:mm");
                return moment(date).format("MM-DD-YY HH:mm");
            }

            return "";
        },
        removeItem(index) {
            this.listConfig.splice(index, 1);
        },
        addColumn() {
            this.submited = false;

            this.listConfig.push({
                value: "",
                removed: true,
                selectVisible: true,
            });
        },
        saveTableConfig() {
            this.submited = true;
            let valid = true;

            this.listConfig.forEach((item) => {
                if (!item.name) {
                    valid = false;
                }
            });

            if (valid) {
                for (const item of this.listConfig) {
                    item.selectVisible = false;
                }

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sidebarVisible = false;

                this.$http
                    .put(
                        `/api/${this.$router.currentRoute.params.prefix}/order/config-table`,
                        { config: this.listConfig }
                    )
                    .then((res) => {
                        this.getConfigTable();
                    });
            }
        },
        saveTableOrder(list) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);

            this.$http
                .put(
                    `/api/${this.$router.currentRoute.params.prefix}/order/config-table`,
                    { config: list }
                )
                .then((res) => {
                    this.getConfigTable();
                });
        },
        updateOptionsList(values) {
            const newArray = [];

            if (values) {
                for (const column of this.collunsOptions) {
                    const columnFound = values.find(
                        (f) => f.name == column.value
                    );

                    if (!columnFound) {
                        newArray.push({
                            value: column.value,
                            text: column.text,
                        });
                    }
                }

                this.collunsOptionsHelper = newArray;
            } else {
                this.collunsOptionsHelper = this.collunsOptions.map((x) => ({
                    value: x.value,
                    text: x.text,
                }));
            }
        },
        cellStatusCustom(params) {
            this.startTooltip();
            const { value } = params;

            return `<div class="badge badge-status-custom" style="background-color: ${params.data.color} !important;" data-toggle="tooltip" title="${value}">
                        <div class="add-ellipsis-production-p-pcp">
                            <span >
                                ${value}
                            </span>
                        </div>
                    </div>`;
        },
        onChangeColumn(params) {
            $('[data-toggle="tooltip"]').tooltip("hide");

            if (params.finished && params.column) {
                const columnState = params.columnApi.getColumnState();
                const newOrder = [];
                columnState.forEach((item) => {
                    const column = this.listConfig.find(
                        (f) => f.name === item.colId
                    );

                    if (column) {
                        newOrder.push({
                            name: column.name,
                            removed: column.removed,
                            width: item.width,
                        });
                    }
                });

                this.saveTableOrder(newOrder);
            }
        },
        openSelect(index) {
            const itemFound = this.listConfig[index];

            if (!itemFound.selectVisible) {
                for (const item of this.listConfig) {
                    item.selectVisible = false;
                }

                itemFound.selectVisible = true;

                this.reRenderSelects = false;
                this.$nextTick(() => {
                    this.reRenderSelects = true;
                });
            }
        },
        startTooltip() {
            $(() => {
                $('[data-toggle="tooltip"]').tooltip({
                    boundary: document.getElementById("div-order-list"),
                    template:
                        '<div class="tooltip tooltip-production-p-pcp" tabindex="-1" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                });
            });
        },
        cellEllipsisTooltip(params) {
            this.startTooltip();

            const { value } = params;
            return `
                    <div class="add-ellipsis-production-p-pcp" data-toggle="tooltip" role="tooltip" title="${value}" data-trigger="hover">
                        <span>
                            ${value || ""}
                        </span>
                    </div>
            `;
        },
        resetDefaultDateValue() {
            const date = new Date();
            const start = moment(date).format("YYYY-MM-DD");
            const end = moment(date.setDate(date.getDate() + 7)).format(
                "YYYY-MM-DD"
            );
            this.formPickerValue = [start, end];
        },
    },
    mounted() {
        this.getOrders();
        this.getConfigTable();
        this.resetDefaultDateValue();
        const vm = this;

        $(() => {
            document
                .getElementsByClassName("input-search-orders")[0]
                .setAttribute("tabIndex", "-1");

            const slider = document.querySelector(".b-form-tags-list");
            let mouseDown = false;
            let startX;
            let scrollLeft;

            const startDragging = function (e) {
                mouseDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            };
            const stopDragging = function (event) {
                mouseDown = false;
            };

            slider.addEventListener("mousemove", (e) => {
                e.preventDefault();
                if (!mouseDown) {
                    return;
                }
                const x = e.pageX - slider.offsetLeft;
                const scroll = x - startX;
                slider.scrollLeft = scrollLeft - scroll;
            });

            slider.addEventListener("mousedown", startDragging, false);
            slider.addEventListener("mouseup", stopDragging, false);
            slider.addEventListener("mouseleave", stopDragging, false);

            $(".b-form-tags-input").blur((event) => {
                const input = vm.$refs.formTagOrders;
                input.addTag(event.target.value);
            });
        });
    },
    updated() {
        if (localStorage.getItem("language") != this.language) {
            this.language = localStorage.getItem("language");
            this.showGrid = false;
            this.$nextTick(() => {
                this.showGrid = true;
            });
        }
    },
    destroyed() {
        const tooltips = document.querySelectorAll(".tooltip");
        if (tooltips.length > 0) {
            for (const tooltip of tooltips) {
                tooltip.style.display = "none";
            }
        }
    },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.dropdown-menu__cell {
    background: #fff;
    padding: 8px 0;
    border-radius: 6px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .dropdown-item__cell {
        display: flex;
        align-items: center;
        padding: 0 21px;
        height: 44px;
        gap: 9px;

        span {
            min-width: 113px;
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        &:hover {
            background: #ffede2;

            svg {
                path {
                    fill: #974900;
                }
            }

            span {
                color: #974900;
            }
        }

        &:active {
            background: #ffdbc4;

            svg {
                path {
                    fill: #974900;
                }
            }

            span {
                color: #974900;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .restore-icon {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .order-list {
        .fld__dropdown-c {
            width: 100%;

            & > li {
                width: 100%;

                .fld__dropdown-button {
                    width: 100%;
                    justify-content: center;
                }

                ul {
                    width: 100%;
                    max-width: none !important;
                    display: flex;
                }
            }
        }

        .input-search-orders {
            min-height: 38px;
        }

        .card-title-order-list {
            flex-direction: column;
            gap: 15px;
        }

        .btn-title-action {
            justify-content: center;
        }

        .dropdown-menu {
            width: 100%;
        }

        .btn-title-action.manage-table {
            height: 28px !important;
            font-size: 12px !important;
            margin-top: 10px;
        }

        .div-custom-input {
            margin-top: 10px;
        }

        .btn-title-action {
            padding: 7px 14px !important;
        }
    }
}

.add-ellipsis-production-p-pcp {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip-production-p-pcp {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;

    .tooltip-inner {
        padding: 0;
        min-width: 1%;
        border-radius: 6px;
        overflow: hidden;
        max-width: 288px;
        padding: 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        user-select: none;
    }

    .arrow {
        &::before {
            border-top-color: #323232;
            border-bottom-color: #323232;
        }
    }
}

.swal-warning {
    .swal2-modal {
        padding: 0 !important;
        border-radius: 6px;
        width: 710px;

        .swal2-header {
            background-color: #eceff1;
            padding: 20px 16px;
            border-radius: 6px 6px 0px 0px;
            flex-direction: row;
            justify-content: left;

            .swal2-title {
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                margin: 0;
            }

            .swal2-close {
                border-radius: 6px;
                width: 34px;
                background-color: #ffff;
                height: 34px;
                top: -5px;
                position: absolute;
                right: -5px;
                padding-bottom: 3px;

                &:hover {
                    color: #4c4541;
                }
            }
        }

        .swal2-content {
            padding: 20px 16px;

            .swal2-html-container {
                font-family: "Raleway";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4c4541;
                display: flex !important;
                text-align: left;
            }
        }

        .swal2-actions {
            padding: 0 16px 20px 16px;
            display: flex;
            justify-content: right;

            .swal2-confirm {
                margin-left: 10px !important;
                order: 2;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #ffff;
                border-color: #974900 !important;
                background-color: #974900 !important;
                border-radius: 5px;

                &:hover {
                    box-shadow: none !important;
                }
            }

            .swal2-cancel {
                order: 1;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #974900 !important;
                border-radius: 5px;
                border-color: transparent !important;

                &:hover {
                    background-color: #ffede1 !important;
                }
            }
        }
    }
}

.flatpickr-calendar {
    right: 237px !important;
    top: 185px !important;

    .flatpickr-day {
        &.startRange,
        &.endRange {
            background-color: #974900 !important;
            border-color: #974900 !important;
            color: #ffff !important;
        }

        &.inRange {
            box-shadow: -5px 0 0 #ece0db, 5px 0 0 #ece0db;
            background-color: #ece0db !important;
            border-color: #ece0db !important;
            color: #6e6b7b !important;

            &:hover {
                box-shadow: -5px 0 0 #ece0db, 5px 0 0 #ece0db;
            }
        }

        &.today {
            border-color: #974900;
        }
    }
}

.table-container {
    overflow-x: hidden;
    margin-top: 0px !important;
    padding-top: 8px !important;
}

.order-list {
    .badge-status-custom {
        max-width: 100%;
    }

    .d-action-orders {
        button {
            width: 32px;
            height: 28px;
            padding: 0;

            svg circle {
                color: #974900 !important;
            }
        }

        .dropdown-item {
            padding: 12px 45.84px 12px 21px !important;

            .text-action-orders {
                margin-left: 8px;
            }

            &:hover {
                background-color: #fbeee8;

                svg path {
                    fill: #974900 !important;
                }

                span {
                    color: #974900 !important;
                }
            }

            &.disabled {
                svg {
                    path {
                        fill: #cfc4be !important;
                    }
                }

                .text-action-orders {
                    color: #cfc4be !important;
                }
            }
        }

        button:hover {
            background-color: #ffdbc4 !important;
        }

        svg {
            margin: 0 !important;
        }
    }

    .card-body {
        padding: 16px;
    }

    .input-search-orders {
        height: 100%;

        ul {
            width: 100%;
            cursor: text !important;
        }
    }

    .card-title-order-list {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .btn-title-action {
        padding: 4px 10px;
        display: flex;
        align-items: center;
        background-color: #ffffff !important;
        color: #974900 !important;
        border-radius: 6px;
        border: 1px solid #974900 !important;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.0125em !important;

        .reload-icon {
            margin-right: 6px;
        }

        .dropdown-icon {
            margin-left: 6px;
        }

        &:hover {
            background-color: #ffede1 !important;
            box-shadow: none !important;
        }

        &.reload {
            svg {
                margin-right: 7px;
            }
        }

        &:focus {
            background-color: transparent !important;
        }
    }

    .btn-title-action.manage-table {
        padding: 6px 21px;
        height: 38px;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .dropdown-toggle {
        &::after {
            content: none !important;
        }
    }

    .dropdown-menu {
        min-width: 180px !important;

        .dropdown-item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;

            &:hover {
                background-color: #ffede2 !important;
                color: #974900 !important;

                span {
                    color: #974900 !important;
                }
            }

            &:active {
                background-color: #ffede2 !important;
                color: #974900 !important;
            }
        }

        .dropdown-item.active {
            color: #ffffff !important;
            background-color: #974900 !important;

            span {
                color: #ffffff !important;
            }
        }
    }

    .filter-order {
        display: flex;
        justify-content: space-between;
        margin-top: 1.2rem;
        gap: 8px;
    }

    .close-icon {
        cursor: pointer;
    }

    .separator {
        width: 407px;
        height: 1px;
        background: #cfc4be;
    }

    .sidebar-title {
        font-weight: 600;
        font-size: 18px;
        color: #4c4541;
    }

    .date-picker-modal-filter-orders {
        position: relative;
        width: 100% !important;
        height: 100% !important;

        fieldset {
            height: 100%;

            div {
                height: 100%;

                input {
                    height: 100%;
                }
            }
        }

        span {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;
        }

        input {
            position: relative;
            z-index: 2;
            color: transparent;
            border: none;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }

        input.form-control {
            padding: 0.2rem !important;
        }

        .form-group {
            margin-bottom: 0 !important;
        }

        svg {
            display: none;
        }

        .dt-picker-component {
            height: 100%;
        }
    }

    .input-search-orders {
        &.focus {
            border-color: #cfc4be !important;
            box-shadow: none;
        }

        &.b-form-tags {
            padding: 0 !important;
            display: flex;
            align-items: center !important;

            .b-form-tags-list {
                width: 100% !important;
                margin-left: 14px !important;
                margin-top: 0;
                height: 100% !important;
                flex-wrap: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow: scroll;
                overflow-y: hidden;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                &::-webkit-scrollbar {
                    display: none !important;
                }

                .b-from-tags-field {
                    margin: 9px 0;
                }
            }
        }

        .b-form-tags-button {
            display: none;
        }

        .b-form-tag {
            background-color: #974900;
            margin-right: 0.6rem;
            padding: 2px 8px !important;
            font-size: 13px;
        }

        ::placeholder {
            color: #cfc4be;
        }
    }

    .multiselect--disabled {
        .multiselect__single {
            background-color: #f5f5f5 !important;
            opacity: 0.6;
        }
    }

    .multiselect__input {
        border: 1px solid transparent !important;
    }

    .ag-root {
        .ag-body {
            .ag-body-viewport {
                .ag-center-cols-container {
                    .ag-row {
                        .ag-cell-value {
                            & > span {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.overlay-mensage {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.card-orders-list-title {
    font-weight: 600;
    font-size: 18px;
    color: #4c4541;
}

.sidebar-manage-table {
    .b-sidebar-header {
        display: contents;
    }

    .b-sidebar {
        height: 100% !important;

        .b-sidebar-body {
            &::-webkit-scrollbar {
                display: none !important;
            }
        }
    }

    .itens-drag {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    .info-sidebar {
        padding: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998f8a;
    }

    .list-group {
        .list-group-item {
            display: flex;
            align-items: center;
            padding-left: 40px;
            border: none;

            &:hover {
                background-color: #ffede2;
                padding-left: 20px;

                .handle {
                    display: block;
                }
            }

            .handle {
                display: none;
                margin-right: 12px;
            }

            .col-input-icon {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .single-select {
                    width: 100%;

                    .multiselect--disabled {
                        border-radius: 6px;

                        .multiselect__tags {
                            background: #eeeeee !important;
                            border: 1px solid #7e7570 !important;
                        }
                    }
                }

                .name-column {
                    border-radius: 6px !important;
                    border: 1px #d8d6de solid;
                    padding: 7px 10px 7px 10px;

                    &.disabled {
                        background-color: #f5f5f5 !important;
                        opacity: 0.6;
                    }
                }

                .icon-action {
                    margin-left: 8px;
                    padding: 8px 9px;
                    display: flex;
                    align-items: center;

                    &.trash {
                        &:hover {
                            background-color: #ffcdd2;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }

    .footer-sidebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        .btn-footer {
            box-shadow: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            border-radius: 5px;

            &:hover {
                box-shadow: none;
            }

            &.btn-reset {
                background-color: transparent !important;
                color: #974900 !important;
                font-size: 12px !important;
                border: 1px solid transparent !important;
                padding: 4px 14px;

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            &.btn-cancel {
                background-color: transparent !important;
                color: #974900 !important;
                border: 1px solid #974900 !important;
                margin-right: 16px;
                padding: 9px 21px;

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            &.btn-save {
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;
                padding: 9px 21px;

                &:hover {
                    background-color: #9f5714 !important;
                }

                &:active {
                    background-color: #a45f1f !important;
                }
            }

            .btn-footer-left {
                display: flex;
            }
        }
    }

    .btn-add-collumn {
        margin-top: 8px;
        margin-left: 16px;
        background-color: transparent !important;
        color: #974900 !important;
        font-size: 12px !important;
        border: 1px solid transparent !important;
        padding: 6px 14px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #ffede2 !important;
            box-shadow: none !important;
        }

        &:active {
            background-color: #ffdbc4 !important;
        }

        &:disabled {
            background-color: #ece0db !important;
            color: #7e7570 !important;

            svg {
                filter: invert(48%) sepia(4%) saturate(712%) hue-rotate(337deg)
                    brightness(95%) contrast(87%) !important;
            }
        }

        .add-circle-icon {
            margin-right: 5px;
        }
    }
}

@media (max-width: 480px) {
    .b-sidebar {
        width: 100% !important;

        .b-sidebar-body {
            overflow-x: hidden;
        }
    }

    .btn-reset-text {
        display: none;
    }
}
</style>
